import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-header',
  templateUrl: './auth-header.component.html',
  styleUrls: ['./auth-header.component.css']
})
export class AuthHeaderComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  public onClickApplicant() {
    this.router.navigate(['auth/sign-in'], {queryParams : {type : 'applicant'}}); 
  }
  
  public onClickStaff() {
    this.router.navigate(['auth/sign-in'], {queryParams : {type : 'admin'}});
  }

}
