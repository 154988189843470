import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';


import { LayoutsModule } from './core/layouts.module';
import { CoreModule } from './core/core.module';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
// import { NgxOtpInputModule } from 'ngx-otp-input';

const TOASTR_OPTIONS = {
  timeOut: 2000,
  positionClass: 'toast-top-right',
  preventDuplicates: true,
}

@NgModule({
  declarations: [
    AppComponent,
    
  
    
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    LayoutsModule,
    ToastrModule.forRoot(TOASTR_OPTIONS),
    NgxUiLoaderModule,
    NgxUiLoaderRouterModule,
    NgxUiLoaderHttpModule,
    // NgxOtpInputModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBlg_n_Iika9tpYMrEK8I1QFHl277ac4Y0'
    }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
