import { staticURLs } from './staticURL';
import { urls, baseUrl } from './url';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  host: `${baseUrl}`,
  alarmPermitHost: 'https://ap.appening.xyz/',
  tradeWasteHost: 'https://tw.appening.xyz/',
  cannabisHost:'https://cb.appening.xyz/',
  dogUrl:'https://dog.appening.xyz/',

  ...urls,
  ...staticURLs
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
