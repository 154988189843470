import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs/operators';
import { UsersService } from '../users';
const credentialsKey = 'currentUser';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  public currentApplication = new BehaviorSubject(null);
  public selectedStaff = new BehaviorSubject(null)
  public ApplicationData = new BehaviorSubject(false);
  public submissionData = new BehaviorSubject([]);
  public aprovalsDetails = new BehaviorSubject([]);
  private apiUrl = 'https://api.zippopotam.us/us';
  constructor(private http: HttpClient,private userService:UsersService, private toasterService: ToastrService) { }
  setCurrentApplication(application) {
    this.currentApplication.next(application);
  }

  getCurrentApplication(): Observable<any> {
    return this.currentApplication.asObservable();
  }
  ApplicationDatas(): Observable<any> {
    return this.ApplicationData.asObservable();
  }
  submissionDatas(): Observable<any> {
    return this.submissionData.asObservable();
  }
  getZipCodes() {
    return this.http.get(this.apiUrl);
  }


  setCurrentStaff(data) {
    sessionStorage.setItem('selectedStaff', JSON.stringify(data));
  }

  getCurrentStaff() {
    return sessionStorage.getItem('selectedStaff') ? (JSON.parse(sessionStorage.getItem('selectedStaff')) || null) : null
  }

  removeCurrentStaff() {
    sessionStorage.removeItem('selectedStaff');
  }

  getApplicationList(pagination): Observable<any> {
    const href = environment.getAdminApplication;
    return this.http.get<any>(href, { params: pagination }).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data;
      }
    }));
  }
  adminexpirePermit(pagination): Observable<any> {
    const href = environment.adminexpirePermit;
    return this.http.get<any>(href, { params: pagination }).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data;
      }
    }));
  }

  issuedDeniedPermit(pagination): Observable<any> {
    const href = environment.issuedDeniedPermit;
    return this.http.get<any>(href, { params: pagination }).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data;
      }
    }));
  }

  getAdminDasboard(): Observable<any> {
    const href = environment.adminDasboard;
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  saveAddressCSVFile(payload): Observable<any> {
    const href = environment['saveAddress'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  saveZipCodeCSVFile(payload): Observable<any> {
    const href = environment['saveZipCodeCSVFile'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }
  postSelectedData(payload): Observable<any> {
    const href = environment['dashboardMail'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        this.toasterService.success("notification send successfully");
        return data.response;
      }
    }));
  }
  sendSmsExpirePermit(payload): Observable<any> {
    const href = environment['sendSmsExpirePermit'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        this.toasterService.success("sms send successfully");
        return data.response;
      }
    }));
  }

  getCityAdminList(pagination): Observable<any> {
    const href = environment['getCityAdminList'];
    return this.http.get<any>(href, { params: pagination }).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data;
      }
    }));
  }

  resendStaffLink(id): Observable<any> {
    const href = environment['resendStaffLink'];
    return this.http.post<any>(href, id).pipe(map((data: any) => {
      if (data.status == 'success') {
        this.toasterService.success("Link Sent Successfully");
        return data.response;
      }
    }));
  }

  addCityAdmin(toSend): Observable<any> {
    const href = environment['addCityAdmin'];
    return this.http.post<any>(href, toSend).pipe(map((data: any) => {
      if (data.status == 'success') {
        this.toasterService.success("Added Successfully");
        return data;
      }
    }));
  }

  addType(toSend): Observable<any> {
    const href = environment['addType'];
    return this.http.post<any>(href, toSend).pipe(map((data: any) => {
      if (data.status == 'success') {
        if(!toSend.type_id){
          this.toasterService.success("Type Added Successfully");
        }else{
           this.toasterService.success("Type Update Successfully");
        }
        return data;
      }
    }));
  }

  addSubType(toSend): Observable<any> {
    const href = environment['addSubType'];
    return this.http.post<any>(href, toSend).pipe(map((data: any) => {
      if (data.status == 'success') {
        if(!toSend.sub_type_id){
          this.toasterService.success("Sub Type Added Successfully");
        }else{
            this.toasterService.success("Sub Type Update Successfully");
        }
        return data;
      }
    }));
  }

  updateCityAdmin(payload): Observable<any> {
    const href = environment['updateCityAdmin'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data;
      }
    }));
  }

  getCityAdmin(id): Observable<any> {
    const href = `${environment.getCityAdminById}/${id}`;
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  getAllTypeList(id): Observable<any> {
    const href = `${environment.allTypeList}/?city_id=${id}`;
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  getTypeList(pagination): Observable<any> {
    const href = environment['typeList'];
    return this.http.get<any>(href, { params: pagination }).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data;
      }
    }));
  }

  document(payload): Observable<any>{
    const href = `${environment.documentAdmin}`;
    return this.http.post<any>(href, payload).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data.response;
        }
      )
    );
  }
  getSubTypeList(pagination): Observable<any> {
    const href = environment['subTypeList'];
    return this.http.get<any>(href, { params: pagination }).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data;
      }
    }));
  }

  getAdminStaff(pagination): Observable<any> {
    const href = environment['getCityAdminStaffList'];
    return this.http.get<any>(href, { params: pagination }).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data;
      }
    }));
  }

  addStaff(toSend): Observable<any> {
    const href = environment['addStaffByCityAdmin'];
    return this.http.post<any>(href, toSend).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  getApplicationById(id): Observable<any> {
    const href = environment['adminApplicationById'] + id;
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  updateApplicationByAdmin(payload): Observable<any> {
    const href = environment['applicationUpdateByAdmin'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  postSubmissionReview(payload): Observable<any> {
    const href = environment['postSubmissionReview'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  voidSubmissionReview(payload): Observable<any> {
    const href = environment['voidSubmissionReview'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  postDecision(payload): Observable<any> {
    const href = environment['postDecision'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }
  approvalPlice(payload): Observable<any> {
    const href = environment['approvalPlice'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }
  finalAprovalPolice(payload): Observable<any> {
    const href = environment['finalAprovalPolice'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  associateList(id): Observable<any> {
    const href = `${environment.adminAssociateList}/${id}`;
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  finalDecision(payload): Observable<any> {
    const href = environment['finalDecision'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  voidDecision(payload): Observable<any> {
    const href = environment['voidDecision'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

voidApproval(payload): Observable<any> {
    const href = environment['voidApproval'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  submitNote(payload): Observable<any> {
    const href = environment['submitNotes'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  postEmail(payload): Observable<any> {
    const href = environment['postEmail'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  deleteNote(payload): Observable<any> {
    const href = environment['deleteNote'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        this.toasterService.success("Deleted successfully");
        return data.response;
      }
    }));
  }

  deletePermits(payload): Observable<any> {
    const href = environment['deletePermits'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        this.toasterService.success("Deleted successfully");
        return data.response;
      }
    }));
  }

  postPayment(payload): Observable<any> {
    const href = environment['postPayment'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        this.toasterService.success("Submitted successfully");
        return data.response;
      }
    }));
  }

  feeWaiveOff(payload): Observable<any> {
    const href = environment['waiveOffFee'];
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  managerUploadSignature(payload): Observable<any> {
    const href = `${environment.managerProfileUploadSignature}`
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }
  managerUploadRefund(payload): Observable<any> {
    const href = `${environment.managerUploadRefund}`
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  cityAdminStaffResetPassword(payload): Observable<any> {
    const href = `${environment.cityAdminStaffResetPassword}`
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  searchAddress(searchQuery): Observable<any> {
    const href = environment['searchAddress'];
    return this.http.get<any>(href, { params: searchQuery }).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  addLicenseFee(payload): Observable<any> {
    const href = `${environment.addLicenseFee}`
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  updateLicenseFee(payload): Observable<any> {
    const href = `${environment.updateLicenseFee}`
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  deleteLicenseFee(payload): Observable<any> {
    const href = `${environment.deleteLicenseFee}`
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }
  deleteType(payload): Observable<any> {
    const href = `${environment.deleteType}`
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }
  deleteSubType(payload): Observable<any> {
    const href = `${environment.deleteSubType}`
    return this.http.post<any>(href, payload).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  removeOwnerOfficerByAdmin(payload) {
    const href = `${environment.deleteOwner}`;
    return this.http.post<any>(href, payload).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  deleteDocumentByAdmin(payload) {
    const href = `${environment.deleteDocumentAdmin}`;
    return this.http.post<any>(href, payload).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  editOwnerOfficerByAdmin(payload) {
    const href = `${environment.editOwnerOfficerAdmin}`;
    return this.http.post<any>(href, payload).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  sendAdditionalInfoDocLink(id) {
    const href = `${environment.sendApplicationAdditionalInfoAdmin}`;
    return this.http.get<any>(href, { params: id }).pipe(map(
      (data) => {
        if (data.status === 'success') {
          return data;
        }
      }
    ));
  }
  getPaymentDetail(data: any): Observable<any> {
    const href = environment['getAdminPaymentList'];
    return this.http.get<any>(href, { params: data }).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data;
      }
    }))
  }
  zipCodeLists(): Observable<any> {
    const href = environment['zipCodeLists'];
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }))
  }

  downloadExportFile(data: any): Observable<any> {
    const href = environment['getExportDataFile'];
    return this.http.get<any>(href, { params: data }).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data;
      }
    }))
  }

  getEin(searchQuery): Observable<any> {
    const href = environment['adminSearchEin'];
    return this.http.get<any>(href, { params: searchQuery }).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  getUserDataLineChart(month): Observable<any> {
    const href = environment['getUserDataLineChart'];
    return this.http.get<any>(href, { params: month }).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }
  
  associatePermit(payload): Observable<any> {
    const href = `${environment.adminAssociate}`;
    return this.http.post<any>(href, payload).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }
  
  getTypesList(): Observable<any> {
    const href = environment['getTypeList'];
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  getSubTypesList(id): Observable<any> {
    const href = `${environment.getSubTypeList}/${id}`;
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }
  getTimeLine(id): Observable<any> {
    const href = `${environment.getTimeLine}/${id}`;
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data;
      }
    }));
  }

  einDocumentList(id){
    const href = `${environment.admineinDocumentList}/${id}`;
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  einAdmimDeleteDocument(payload): Observable<any> {
    const href = `${environment.einAdmimDeleteDocument}`;
    return this.http.post<any>(href, payload).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  einAdminDocumentUpload(payload): Observable<any>{
    const href = `${environment.einAdminDocumentUpload}`;
    return this.http.post<any>(href, payload).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data.response;
        }
      )
    );
  }
  deleteDocumentEin(payload): Observable<any>{
    const href = `${environment.adminDeleteDocumentEin}`;
    return this.http.post<any>(href, payload).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data.response;
        }
      )
    );
  }
  sendOtpProfile(): Observable<any> {
    const href = `${environment.sendOtpProfile}`;
    return this.http.get<any>(href).pipe(map((res: any) => {
      console.log("re",res.status)
      if (res.status == 'success') {
        return res;
      }
    }));
  }
  verifiyOtpProfileadmin(data){
    const href = environment.verifiyOtpProfileadmin;
    return this.http.post<any>(href, data).pipe(map((res: any) => {
      if (res.status === 'success') {
        const storage = localStorage;
        storage.setItem(credentialsKey, JSON.stringify(res.response));
      }
      if (res.status == 'success') {
        return res;
      }
    }));
  }
  emailResendVerificationadmin(data){
    const href = environment.emailResendVerificationadmin;
    // return this.http.post<any>(href, data).pipe(map((data: any) => {
    //   // if (data.status == 'success') {
    //     return data;
    //   // }
    // }));
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }
  sendResetPasswordLinkStaff(data): Observable<any> {
    const href = environment.sendResetPasswordLinkStaff;
    return this.http.post<any>(href, data).pipe(map((res: any) => {
      if (res.status == 'success') {
        return res;
      }
    }));
  }
  verifyCSRFToken(tokenQuery) {
    console.log(tokenQuery,tokenQuery)
    const href = `${environment.verifyCSRFTokenAdmin}`;
    return this.http.get<any>(href, { params: tokenQuery }).pipe(map(
      (data) => {
        if (data.status === 'success') {
          const storage = localStorage;
          storage.setItem(credentialsKey, JSON.stringify(data.response));
          this.userService.userDetails.next(data.response);
          return data.response
        }
      }
    ));
  }
}
