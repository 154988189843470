import { Component, OnDestroy, OnInit,Renderer2,Inject} from '@angular/core';
import { NavigationStart, Router,NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from './core/authentication/authentication.service';
import { LoaderService } from './core/services';
// import { ToasterConfig } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
export let browserRefresh = false;
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  // public config: ToasterConfig = new ToasterConfig({
  //   animation: 'fade',
  //   positionClass: 'toast-top-full-width',
  //   showCloseButton: true,
  //   timeout: 8000
  // });

  public interval: any;
  public isTrue = true;
  isConditionMet = true;
  private subscription: Subscription = new Subscription();
 public currentPath:any;
 encryptedData:any;
  constructor(private authenticationService: AuthenticationService,private location: Location,private titleService: Title, @Inject(DOCUMENT) private document: Document, private router: Router, private loaderService: LoaderService) {
    this.titleService.setTitle('Business License');
    this.encryptedData = new URLSearchParams(window.location.search).get('csrfToken');
    let url = new URLSearchParams(window.location.search).get('id');
    let token =  sessionStorage.getItem("csrfToken")   
    console.log("value is ",url )  
    this.subscription.add(
      router.events.subscribe((event: any) => {
        
        if (event instanceof NavigationEnd) { 
          this.currentPath = this.router.url;
          var updatedURL = this.router.url.replace(/[?&]id=([^&#]*)/, '');

          console.log("url updatedURL",updatedURL)
          this.changetitle(router)
          // if (event instanceof NavigationStart) {      
            browserRefresh = !router.navigated;
            if(updatedURL == '/auth/sign-in' || event.url == '/' || updatedURL == '/auth/registration-form' || updatedURL == '/auth/register' || updatedURL == '/auth/otp'){
              this.isConditionMet = true;
              console.log("conditions match",updatedURL)

              this.setTitle(1);
        
            }else{
              console.log("event.url",event.url)
              this.isConditionMet = false;
              this.setTitle(2);
        
            }
          // }
          if(updatedURL == '/auth/sign-in' || updatedURL == '/auth/otp' || updatedURL == '/auth/registration-form' || updatedURL == '/auth/register' ){
            this.isTrue = false
          }else{
            this.isTrue = true;
          }
          console.log("Current Path:", updatedURL);
          // browserRefresh = !router.navigated;
        }
      })
    );
    // this.subscription.add(router.events.subscribe((event: any) => {
    //   if (event instanceof NavigationStart) {
    //     browserRefresh = !router.navigated;
    //   }
    // }));
  }
changetitle(router){
  
}
getTitle(): string {
  console.log("data is ",this.isConditionMet )
  return this.isConditionMet ?  'Business License' : 'License Portal' ;
}


getFavicon(): string {
  return this.isConditionMet ? 'assets/images/fav.png' : 'assets/images/busLic.png';
}

setTitle(val) {
  if(val == 1){
    this.titleService.setTitle('License Portal');
    this.getTitle()
  }else{
    
    this.titleService.setTitle('Business License');
    this.getTitle()

  }

  this.setFavicon();
}
setFavicon() {
  const linkElement = this.document.querySelector('link[rel="icon"]') || this.document.createElement('link');
  this.document.head.appendChild(linkElement);

  linkElement.setAttribute('rel', 'icon');
  linkElement.setAttribute('type', 'image/x-icon');
  linkElement.setAttribute('href', this.getFavicon());
}
  ngOnInit(): void {
    this.subscription.add(this.authenticationService.obsLoginDetails.subscribe((data: any) => {
      if (data) {
      } else {
        this.subscription.add(this.authenticationService.getUserInfo().subscribe(user => {
          const currentApplicantUser = user ? user : null;
        }));
      }
    }));
    this.subscription.add(this.authenticationService.obsLoggedOutBs.subscribe((data: any) => {
      if (data) {
        clearInterval(this.interval);
      }
    }))
    this.loaderService.show();
    setTimeout(() => {
      this.loaderService.hide();
    }, 7000)
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  intervalFunction() {
    this.interval = setInterval(() => {
      this.subscription.add(this.authenticationService.getCSRFToken().subscribe((data: any) => {
        sessionStorage.setItem('csrfToken', data);
      }));
    }, 50000);
  }

}
