import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { tap, skip, map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { AuthenticationService } from 'src/app/core/authentication/authentication.service';

import { environment } from 'src/environments/environment';
import { Register } from 'src/app/shared';
const credentialsKey = 'currentUser';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  downloadFile: string;
  public isSaveAndExit = new BehaviorSubject({ isSaveAndExit: false, currentForm: null });
  public ApplicationData = new BehaviorSubject(false);

  public isResubmit = new BehaviorSubject({ isResubmit: false, currentForm: null });

  public userDetails = new BehaviorSubject(null);
  public userDetailsObs = this.userDetails.asObservable();

  public hideSaveAndExit = new BehaviorSubject(false);
  public obsHideSaveAndExit = this.hideSaveAndExit.asObservable();

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) { }

  registerUser(data: Register): Observable<any> {
    const href = `${environment.register}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
            this.authenticationService.setUser(data.response);
          }
          return data;
        }
      )
    );
  }

  updateUserProfile(payload): Observable<any> {
    const href = `${environment.updateProfile}`;
    return this.http.post<any>(href, payload).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }
  deleteDocument(payload): Observable<any> {
    const href = `${environment.deleteDocument}`;
    return this.http.post<any>(href, payload).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }
  einDeleteDocument(payload): Observable<any> {
    const href = `${environment.einDeleteDocument}`;
    return this.http.post<any>(href, payload).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  associatePermit(payload): Observable<any> {
    const href = `${environment.associatePermit}`;
    return this.http.post<any>(href, payload).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  document(payload): Observable<any>{
    const href = `${environment.documentUploadUser}`;
    return this.http.post<any>(href, payload).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data.response;
        }
      )
    );
  }
  einDocumentUpload(payload): Observable<any>{
    const href = `${environment.einDocumentUpload}`;
    return this.http.post<any>(href, payload).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data.response;
        }
      )
    );
  }
  einDocumentList(id){
    const href = `${environment.einDocumentList}/${id}`;
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }
  

  setCurrentApplication(application) {
    sessionStorage.setItem("application", JSON.stringify(application));
  }

  removeApplication() {
    sessionStorage.removeItem("application");
  }

  getCurrentApplication() {
    return sessionStorage.getItem("application") ? (JSON.parse(sessionStorage.getItem("application")) || null) : null;
  }

  checkSaveAndExit(): Observable<any> {
    return this.isSaveAndExit.asObservable().pipe(skip(1));
  }
  

  checkResubmit():  Observable<any> {
    return this.isResubmit.asObservable().pipe(skip(1));
  }

  getApplicationList(pagination): Observable<any> {
    const href = environment.getApplicationList;
    return this.http.get<any>(href, {params: pagination}).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data;
      }
    }));
  }
  sendProfileOtp(payload){
    const href = environment.sendProfileOtp;
    return this.http.get<any>(href, {params: payload}).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data;
      }
    }));
  }

  getApplicationById(id): Observable<any> {
    const href = `${environment.getApplicationById}/${id}`;
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }
  regestrationOtpProfile(data): Observable<any> {
    const href = environment.verifiyOtpProfile;
    return this.http.post<any>(href, data).pipe(map((res: any) => {
      if (res.status == 'success') {
        return res.response;
      }
    }));
  }
  verifiyOtpProfile(data){
    const href = environment.verifiyOtpProfile;
    return this.http.post<any>(href, data).pipe(map((res: any) => {
      if (res.status === 'success') {
        const storage = localStorage;
        storage.setItem(credentialsKey, JSON.stringify(res.response));
      }
      if (res.status == 'success') {
        return res;
      }
    }));
  }
  getSubTypeListId(id,sub_id): Observable<any> {
    // console.log("city_id",id,sub_id)
    const href = `${environment.SubTypeList}/?city_id=${id}&type_id=${sub_id}`;
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }
  SubTypeList
  // getEin(einNumber): Observable<any> {
  //   const href = `${environment.ein}?''ein''='${einNumber}`;
  //   return this.http.get<any>(href).pipe(map((data: any) => {
  //     if (data.status == 'success') {
  //       return data.response;
  //     }
  //   }));
  // }

  getEin(searchQuery): Observable<any> {
    const href = environment['ein'];
    return this.http.get<any>(href, { params: searchQuery }).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  getTypeList(id): Observable<any> {
    const href = `${environment.UsertypeList}/${id}`;
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

   associateList(id): Observable<any> {
    const href = `${environment.associateList}/${id}`;
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  // resetAssociate(payload): Observable<any> {
  //   const href = `${environment.resetAssociate}`;
  //   return this.http.get<any>(href,payload).pipe(map((data: any) => {
  //     if (data.status == 'success') {
  //       return data.response;
  //     }
  //   }));
  // }

   resetAssociate(payload): Observable<any> {
    const href = `${environment.resetAssociate}`;
    return this.http.post<any>(href, payload).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  getCityList(): Observable<any> {
    const href = environment['getCityList'];
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  setSelectedCity(id) {
    sessionStorage.setItem('selectedCity', id);
  }



  getSelectedCity() {
    return sessionStorage.getItem('selectedCity')
  }

  removeSelectedCity() {
    sessionStorage.removeItem('selectedCity');
  }

  setLicenseTypeForSelectedCity(licenseType) {
    sessionStorage.setItem('licenseType', licenseType);
  }

  getLicenseTypeForSelectedCity() {
    // return JSON.parse(sessionStorage.getItem("licenseType"));
  }

  removeLicenseTypeForSelectedCity() {
    sessionStorage.removeItem('licenseType');
  }
  getTimeLine(id): Observable<any> {
    const href = `${environment.getTimeLineUser}/${id}`;
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data;
      }
    }));
  }
  zipCodeList(id): Observable<any> {
    const href = `${environment.zipCodeList}/${id}`;
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }
  // storeList(application_id,search): Observable<any> {
  //   const href = `${environment.storeList}?application_id= ${830}&search=
  //   ${'90-9090909'}`;
  //   return this.http.get<any>(href).pipe(map((data: any) => {
  //     if (data.status == 'success') {
  //       return data.response;
  //     }
  //   }));
  // }
  //  searchQuery = '90-9090909' 
  storeList(searchQuery): Observable<any> {
    const href = environment['storeList'];
    return this.http.get<any>(href, { params: searchQuery }).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }
  getAddressByPermitNumber(searchQuery): Observable<any> {
    const href = environment['getAddressByPermitNumber'];
    return this.http.get<any>(href, { params: searchQuery }).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

}
